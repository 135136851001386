// 
// badge.scss
//

.badge {
    color: $white;
    box-shadow: $shadow;
}

.badge-light {
    color: $gray-800;
}
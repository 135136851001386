// 
// popover.scss
//


.popover {
    box-shadow: $shadow;
}

.popover-header {
    margin-top: 0;
}